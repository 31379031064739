<template>
  <div class="doc-section" ref="target">
    <div
      class="doc-section__title"
      @click="showGroup = !showGroup"
    >
      <img :src="acc.preview">
      <h4>{{acc.title}}</h4>
      <img
        src="@/assets/images/arrow-accordion.svg"
        :style="`transform: rotate(${showGroup ? '180deg' : '0'})`"
      >
    </div>
    <transition name="default">
      <div
        class="doc-section__group"
        v-if="showGroup"
      >
        <ECDocItem
          v-for="doc in acc.group"
          :key="doc.label"
          :label="doc.label"
          :link="doc.link"
          isolate
        />
      </div>
    </transition>
  </div>
</template>

<script setup>
import { defineProps, ref } from 'vue'
import { onClickOutside } from '@vueuse/core'
import ECDocItem from './ECDockItem'

const props = defineProps({
  acc: {
    type: Object,
    required: true,
  }
})

const download = file => window.open(`${process.env.VUE_APP_DOCS_REPOSITORY}/docs/${file}`, '_blank');
const target = ref(null)
const showGroup = ref(false)

onClickOutside(target, (event) => showGroup.value = false)
</script>

<style scoped lang="scss">
.doc-section{
  background: white;
  border-radius: 16px;
  margin-bottom: 16px;
  &__title{
    display: flex;
    padding: 17px 20px;
    align-items: center;
    cursor: pointer;
    h4{
      flex: 1;
      font-size: 16px;
      line-height: 16px;
    }
    img{
      margin-right: 16px;
      transition: ease .2s;
      &:last-child{
        margin: 0;
        cursor: pointer;
      }
    }
  }
  &__group{
    padding: 10px 0;
    padding-left: 35px;
    position: relative;
    &:before{
      content: '';
      position: absolute;
      left: 17px;
      right: 17px;
      top: 0;
      height: 2px;
      border-radius: 13px;
      background: #D9D9D9;
    }
  }
}
</style>
