<template>
  <div class="page">
    <div class="page-title">Документы</div>

    <ECDocItem
      v-for="doc in docsSolo"
      :key="doc.label"
      :label="doc.label"
      :link="doc.link"
      solo
      isolate
    />

    <div class="docs-list">
      <ECDocsSection
        v-for="parent in docs"
        :key="parent.id"
        :acc="parent"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import ECDocsSection from './ECDocsSection'
import ECDocItem from './ECDockItem'
import axios from 'axios'

onMounted(()=> getDocs())

const docs = ref([])
const docsSolo = ref([])

const getDocs = async () => {
  const req = await axios.get(`${process.env.VUE_APP_STRAPI_API}/docs?populate=documents,icon`)

  req.data.data.forEach(item => {
    if(item.attributes.name === '*'){
      docsSolo.value = item.attributes.documents.data.map(item => {
        return {
          label: item.attributes.alternativeText,
          link: `${process.env.VUE_APP_STRAPI_UPLOADS}${item.attributes.url}`
        }
      })
    } else {
      docs.value.push({
        id: item.id,
        title: item.attributes.name,
        preview: item.attributes.icon.data ? `${process.env.VUE_APP_STRAPI_UPLOADS}${item.attributes.icon.data.attributes.url}` : '',
        group: item.attributes.documents.data.map(d => {
          return {
            label: d.attributes.alternativeText,
            link: `${process.env.VUE_APP_STRAPI_UPLOADS}${d.attributes.url}`
          }
        })
      })
    }
  });
}
</script>

<style scoped lang="scss"></style>
